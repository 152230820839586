import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["account", "area", "team", "manager", "subcategory", "critical", "origin", "kind"]
  
  connect(){
    console.log("connecté à tickets controller")
  }

  update_area(event){
    let accountId = this.accountTarget.value
    let areaTarget = this.areaTarget.id
    
    if (!accountId) return
    
    get(`/accounts/${accountId}/areas?target=${areaTarget}`, {
      responseKind: "turbo-stream"
    })

    get(`/accounts/${accountId}/infos?area_id=${this.areaTarget.value}`, {
      responseKind: "turbo-stream"
    })
  }

  update_infos(event){
    let accountId = this.accountTarget.value
    let areaId = this.areaTarget.value
    
    if (!accountId) return
    
    get(`/accounts/${accountId}/infos?area_id=${areaId}`, {
      responseKind: "turbo-stream"
    })
  }

  update_managers(event){
    let teamId = this.teamTarget.value
    let target = this.managerTarget.id

    get(`/teams/${teamId}/managers?target=${target}`, {
      responseKind: "turbo-stream"
    })
  }

  async update_subcategories(event) {
    const categoryId = event.target.value
    const subcategorySelect = this.subcategoryTarget
    let defaultSelected = false
    
    subcategorySelect.innerHTML = '<option value="">-- choisir la sous-catégorie --</option>'
    
    if (!categoryId) return

    try {
      const response = await get(`/ticket_categories/${categoryId}/subcategories`, {
        responseKind: "json"
      })
      
      if (response.ok) {
        const subcategories = await response.json
        subcategories.forEach(subcategory => {
          const option = document.createElement('option')
          option.value = subcategory.id
          option.textContent = subcategory.name
          if (subcategory.default) {
            option.selected = true
            defaultSelected = true
          }
          subcategorySelect.appendChild(option)
        })
        
        if (defaultSelected) {
          this.update_critical({ target: subcategorySelect })
        }
      }
    } catch (error) {
      console.error("Erreur lors du chargement des sous-catégories:", error)
    }
  }

  async update_kinds(event) {
    const originId = event.target.value
    const kindSelect = this.kindTarget
    
    kindSelect.innerHTML = '<option value="">-- choisir le type de ticket --</option>'
    
    if (!originId) return

    try {
      const response = await get(`/ticket_origins/${originId}/kinds`, {
        responseKind: "json"
      })
      
      if (response.ok) {
        const kinds = await response.json
        kinds.forEach(kind => {
          const option = document.createElement('option')
          option.value = kind.id
          option.textContent = kind.name
          if (kind.default) {
            option.selected = true
          }
          kindSelect.appendChild(option)
        })
      }
    } catch (error) {
      console.error("Erreur lors du chargement des types de tickets:", error)
    }
  }

  async update_critical(event) {
    const subcategoryId = this.subcategoryTarget.value
    if (subcategoryId) {
      const response = await get(`/ticket_subcategories/${subcategoryId}/get_critical`, {
        responseKind: "json"
      })
      if (response.ok) {
        const data = await response.json
        if (data.critical_id) {
          this.criticalTarget.value = data.critical_id
        }
      }
    }
  }
}