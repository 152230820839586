import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "pdfViewer", "iframe", "openInTab"]
  
  connect() {
    console.log("PDF Viewer controller connected")
    
    // Ajouter un écouteur d'événements pour tous les clics
    this.clickHandler = this.handleContentClick.bind(this)
    this.contentTarget.addEventListener("click", this.clickHandler)
    
    // Vérifier initialement s'il y a des liens PDF
    setTimeout(() => {
      this.hasPdfLinks()
    }, 500)
  }
  
  disconnect() {
    this.contentTarget.removeEventListener("click", this.clickHandler)
  }
  
  handleContentClick(event) {
    // Trouver l'élément a le plus proche qui a été cliqué
    const linkElement = event.target.closest('a')
    
    // Si c'est un lien et que c'est un lien PDF
    if (linkElement && this.isPdfLink(linkElement.href)) {
      event.preventDefault()
      console.log("PDF link clicked:", linkElement.href)
      this.showPdf(linkElement.href)
    }
  }
  
  hasPdfLinks() {
    console.log("Checking for PDF links...")
    // Rechercher tous les liens dans le contenu et ses enfants
    const links = this.contentTarget.querySelectorAll('a')
    
    // Vérifier s'il y a au moins un lien PDF
    for (let i = 0; i < links.length; i++) {
      if (this.isPdfLink(links[i].href)) {
        console.log("Found PDF link:", links[i].href)
        return true
      }
    }
    
    // Si aucun lien PDF n'est trouvé, masquer le visualiseur
    console.log("No PDF links found")
    this.pdfViewerTarget.classList.add('hidden')
    return false
  }
  
  isPdfLink(url) {
    if (!url) return false
    
    const lowerUrl = url.toLowerCase()
    return lowerUrl.endsWith('.pdf') || 
           lowerUrl.includes('.pdf?') || 
           lowerUrl.includes('/pdf/') ||
           lowerUrl.includes('format=pdf')
  }
  
  showPdf(url) {
    console.log("Displaying PDF in iframe:", url)
    
    // Mémoriser l'URL actuelle pour le bouton d'ouverture dans un nouvel onglet
    this.currentPdfUrl = url
    this.openInTabTarget.href = url
    
    // Afficher le conteneur
    this.pdfViewerTarget.classList.remove('hidden')
    this.pdfViewerTarget.style.display = 'block'
    
    try {
      // Approche simplifiée: utiliser directement l'iframe sans la remplacer
      // Cela évite les problèmes d'interférence entre les instances
      this.iframeTarget.src = url
      
      // Ajouter une classe pour indiquer que l'iframe contient un PDF
      this.iframeTarget.classList.add('pdf-loaded')
      
      // S'assurer que l'iframe est visible
      this.iframeTarget.style.display = 'block'
      this.iframeTarget.style.height = '384px' // équivalent à h-96
    } catch (error) {
      console.error("Error displaying PDF:", error)
      // En cas d'erreur, ajouter un message à l'utilisateur
      const errorMessage = document.createElement('div')
      errorMessage.className = "text-sm text-red-500 mt-2"
      errorMessage.textContent = "Le PDF ne peut pas être affiché ici. Veuillez cliquer sur 'Ouvrir dans un nouvel onglet'."
      
      // Éviter les doublons
      const existingError = this.pdfViewerTarget.querySelector('.text-red-500')
      if (!existingError) {
        this.pdfViewerTarget.appendChild(errorMessage)
      }
    }
    
    console.log("PDF viewer display style:", getComputedStyle(this.pdfViewerTarget).display)
  }
  
  hidePdf() {
    console.log("Hiding PDF viewer")
    this.pdfViewerTarget.classList.add('hidden')
    this.pdfViewerTarget.style.display = '' // Supprimer le style display en ligne
    this.iframeTarget.src = 'about:blank' // Clear the iframe content
  }
} 